export const messages = {
  de: {
    welcome: 'Willkommen',
    login: 'Anmelden',
    'leo-start': 'LEO starten',
    logout: 'Abmelden',
    'admin-login': 'Zum Lehrer-Login',
    'therapy-login': 'Zum Therapeuten-Login',
    'general-info': 'Testdurchführung',
    'more-info': 'Mehr Infos',
    'invalid-format': 'Dateiformat ungültig!',
    'not-found': 'Oje, hier bist du falsch.',
    super: 'Super!',
    start: 'Start',
    yes: 'Ja',
    no: 'Nein',
    attention: 'Achtung',
    save: 'Speichern',
    edit: 'Bearbeiten',
    next: 'Weiter',
    back: 'Zurück',
    close: 'Schließen',
    here: 'hier',
    send: 'Senden',
    'send-mail': 'E-Mail senden',
    delete: 'Löschen',
    'delete-student': 'Schüler*in löschen',
    cancel: 'Abbrechen',
    evaluate: 'Auswerten',
    'new-password': ' Neues Passwort',
    'confirm-password': 'Passwort bestätigen',
    'change-password': 'Passwort ändern',
    'change-password-info': 'Link abgelaufen? Einen neuen Link bekommen Sie',
    'forgot-password': 'Passwort vergessen?',
    'forgot-password-info':
      'Bitte geben Sie Ihre registrierte E-Mail-Adresse an. Sie erhalten in Kürze eine E-Mail mit weiteren Informationen, wie Sie ein neues Passwort festlegen können.',
    'to-login': 'Zum Login',
    'point-of-no-return': 'Diese Aktion kann nicht rückgängig gemacht werden!',
    'single-therapist': 'Therapeut*in',
    'single-teacher': 'Lehrer*in',
    admin: {
      'edit-teacher': 'Lehrer*in bearbeiten',
      headertitle: 'Admin-Account',
      info: 'Info',
      account: {
        create: 'Klassenlehrer-Zugang erstellen',
        manage: 'Klassenlehrer-Zugänge verwalten',
        'manage-admin': 'Admin-Account verwalten',
        'edit-admin': 'Admin bearbeiten',
      },
      'send-registration': 'Willkommensmail senden',
      'reset-password': 'Passwort zurücksetzen',
      'create-account': 'Zugang hinzufügen',
    },
    teacher: {
      headertitle: 'Lehrer-Account',
      grade: 'Klasse',
      create: 'Schüler*innen hinzufügen',
      'add-student': 'Schüler*in hinzufügen',
      'manual-create': 'Zur LEO Klassenliste hinzufügen',
      code: 'Zugangscode',
      'print-code': 'Zugangscode drucken',
      codes: 'Alle Zugangscodes drucken',
      formA: 'Form A',
      formB: 'Form B',
      'edit-student': 'Schüler*in bearbeiten',
      class: 'Klasse',
      classList: 'Klassenliste',
      'manage-classList': 'Klassenliste verwalten',
      'import-classList': 'Namensliste importieren',
      'download-classList-info':
        'Hier können Sie die Zugangsdaten für die gesamte Klasse downloaden',
      manual: 'Handbuch',
      praxis: 'Praxisbuch',
      faq: 'FAQ',
      infos: 'Infos',
      dashboard: {
        info: {
          1: 'Fügen Sie Ihre Schüler*innen der LEO Klassenliste hinzu.',
          2: 'Klicken Sie auf das Feld <strong>Alle Zugangscodes ausdrucken</strong> (oder auf das Zugangscode-Symbol in der Namenszeile für den jeweiligen Einzelcode) und verteilen Sie diese.',
          3: 'Der/die Schüler*in geht auf die angegebene Webseite-Adresse und gibt die Zugangsdaten ein.',
          4: 'Der Test startet mit <strong>Form A</strong>. Bei einem Folgetest startet LEO automatisch mit Form B.',
          5: 'Der Test kann <strong>nach</strong> jedem Untertest pausiert werden und zum Beispiel am nächsten Tag fortgesetzt werden. Während eines Untertests ist keine Unterbrechung erlaubt.',
          6: 'Der Test kann zu jedem beliebigen Zeitpunkt während des Schuljahres durchgeführt werden.',
          7: 'Der Test kann während des Unterrichts z.B. in der Freiarbeit durchgeführt werden.',
          8: 'Nach vollständiger Testdurchführung erscheint in der LEO Klassenliste in der Spalte Auswertung ein pdf-Dokument mit den Testergebnissen.',
          9: 'Weitere Infos finden Sie im Manual (in der Menüleiste).',
        },
        'create-info': {
          title: 'Sie haben folgende 2 Möglichkeiten Schüler*innen anzulegen: ',
          manually: {
            title: '1. Einzeln hinzufügen',
            info: 'Klicken Sie auf den Button <strong>Schüler*in hinzufügen</strong>. Füllen Sie die Felder aus. Das Kind wird automatisch in die LEO Klassenliste eingetragen.',
          },
          upload: {
            title: '2. Namensliste importieren',
            info: 'Legen Sie eine Namensliste im CSV-Format an und importieren sie diese. Die Namen der Kinder werden automatisch in die LEO-Klassenliste übertragen. Für weitere Infos klicken Sie auf den Button <strong>Namensliste importieren</strong>.',
          },
        },
        'classList-info': {
          edit: 'Klicken Sie auf <strong>bearbeiten</strong>, falls Sie einen Schreibfehler ausbessern wollen.',
          delete:
            'Bei einem Klick auf <strong>löschen</strong> wird der komplette Schüler*innen - Account gelöscht.',
          code: 'Wenn Sie den Zugangscode für den Test einzeln ausdrucken möchten, klicken Sie auf <strong>Zugangscode</strong>',
          info: ' Es muss keine Form ausgewählt werden. LEO startet mit <strong>Form A</strong>. Bei einem nochmaligem Test, startet LEO automatisch mit dem Wiederholungstest Form B (anderes Wortmaterial).',
          end: 'Wenn alle Untertests abgeschlossen sind, erscheint ein grüner Haken und in der Spalte <strong>Auswertung</strong> steht ein pdf-Dokument mit den Testergebnissen zur Verfügung.',
        },
        csv: {
          info: 'Nicht notwendig, wenn die Schüler*innen einzeln hinzugefügt werden.',
          1: '1) Erstellen Sie eine Excel-Tabelle mit den drei Spaltenüberschriften: <br> <strong>ID, Vorname, Nachname.</strong>',
          2: '2) Tragen Sie hier die Namen der SchülerInnen ein.',
          3: '3) Speichern Sie diese Datei im CSV-Format: Dateiendung *.csv. <br>Wichtig: Die Datei sollte im <strong>CSV UTF-8</strong> Format gespeichert werden!',
          4: '4): Klicken Sie auf <strong>Datei auswählen</strong> , wählen Sie Ihre Tabelle aus und klicken Sie dann auf <strong>Namensliste importieren.</strong>',
          error: 'Fehler beim Hochladen?',
          errorInfo:
            'Kontrollieren Sie die <strong>Spaltenüberschriften</strong> und das <strong>Dateiformat</strong>. <br> (Die Datei muss auf .csv enden!).<br> Umlaute falsch? Löschen Sie die entsprechenden Einträge und stellen Sie sicher, dass Ihre Datei im <strong>CSV UTF-8</strong> Format gespeichert ist. <br> Laden Sie die Seite eventuell neu.',
        },
      },
      'add-info':
        'Sie können hier Schüler einzeln hinzufügen oder eine Namensliste hochladen.',
      'no-grade-info':
        'Ihnen ist keine Klasse zugewiesen. Bitte wenden Sie sich an den Administrator.',
      classEvaluation: {
        a: 'Klassenauswertung: Form A',
        b: 'Klassenauswertung: Form B',
      },
    },
    therapist: {
      'edit-admin': 'Admin bearbeiten',
      'edit-therapist': 'Therapeut*in bearbeiten',
      'edit-patient': 'Kind bearbeiten',
      'add-patient': 'Kind hinzufügen',
      create: 'Kind hinzufügen',
      account: {
        create: 'Therapeuten-Zugang erstellen',
        manage: 'Therapeuten-Zugang verwalten',
      },
      patients: 'Übersicht',
      patientsList: 'Therapieliste',
      'manage-classList': 'Therapieliste verwalten',
      dashboard: {
        info: {
          1: 'Fügen Sie Kinder der Therapieliste hinzu.',
          2: 'Klicken Sie auf das Feld <strong>Alle Zugangscodes ausdrucken</strong> (oder auf das Zugangscode-Symbol in der Namenszeile für den jeweiligen Einzelcode) und verteilen Sie diese.',
          3: 'Das Kind geht auf die angegebene Webseite-Adresse und gibt die Zugangsdaten ein.',
          4: 'Der Test startet mit <strong>Form A</strong>. Bei einem Folgetest startet LEO automatisch mit Form B.',
          5: 'Der Test kann <strong>nach</strong> jedem Untertest pausiert werden und zum Beispiel am nächsten Tag fortgesetzt werden. Während eines Untertests ist keine Unterbrechung erlaubt.',
          6: 'Der Test kann zu jedem beliebigen Zeitpunkt während des Schuljahres durchgeführt werden.',
          7: 'Nach vollständiger Testdurchführung erscheint in der Therapieliste in der Spalte Auswertung ein pdf-Dokument mit den Testergebnissen.',
          8: 'Weitere Infos finden Sie im Manual (in der Menüleiste).',
        },
        'create-info': {
          title: 'Sie haben folgende 2 Möglichkeiten ein Kind anzulegen: ',
          manually: {
            title: '1. Einzeln hinzufügen',
            info: 'Klicken Sie auf den Button <strong>Kind hinzufügen</strong>. Füllen Sie die Felder aus. Das Kind wird automatisch in die Therapieliste eingetragen.',
          },
          upload: {
            title: '2. Namensliste importieren',
            info: 'Legen Sie eine Namensliste im CSV-Format an und importieren sie diese. Die Namen der Kinder werden automatisch in die Therapieliste übertragen. Für weitere Infos klicken Sie auf den Button <strong>Namensliste importieren</strong>.',
          },
        },
        'classList-info': {
          edit: 'Klicken Sie auf <strong>bearbeiten</strong>, falls Sie einen Schreibfehler ausbessern wollen.',
          delete:
            'Bei einem Klick auf <strong>löschen</strong> wird der komplette  Account gelöscht.',
          code: 'Wenn Sie den Zugangscode für den Test einzeln ausdrucken möchten, klicken Sie auf <strong>Zugangscode</strong>',
          info: ' Es muss keine Form ausgewählt werden. LEO startet mit <strong>Form A</strong>. Bei einem nochmaligem Test, startet LEO automatisch mit dem Wiederholungstest Form B (anderes Wortmaterial).',
          end: 'Wenn alle Untertests abgeschlossen sind, erscheint ein grüner Haken und in der Spalte <strong>Auswertung</strong> steht ein pdf-Dokument mit den Testergebnissen zur Verfügung.',
        },
        csv: {
          info: 'Nicht notwendig, wenn Kinder einzeln hinzugefügt werden.',
          1: '1) Erstellen Sie eine Excel-Tabelle mit den vier Spaltenüberschriften: <br> <strong>ID, Vorname, Nachname, Schulstufe</strong>',
          2: '2) Tragen Sie hier die Namen der Kinder ein.',
          3: '3) Speichern Sie diese Datei im CSV-Format: Dateiendung *.csv. <br>Wichtig: Die Datei sollte im <strong>CSV UTF-8</strong> Format gespeichert werden!',
          4: '4): Klicken Sie auf <strong>Datei auswählen</strong> , wählen Sie Ihre Tabelle aus und klicken Sie dann auf <strong>Namensliste importieren.</strong>',
          error: 'Fehler beim Hochladen?',
          errorInfo:
            'Kontrollieren Sie die <strong>Spaltenüberschriften</strong> und das <strong>Dateiformat</strong>. <br> (Die Datei muss auf .csv enden!).<br> Umlaute falsch? Löschen Sie die entsprechenden Einträge und stellen Sie sicher, dass Ihre Datei im <strong>CSV UTF-8</strong> Format gespeichert ist.<br> Laden Sie die Seite eventuell neu.',
        },
      },
    },
    snackbar: {
      login: {
        error: {
          grade: 'Überprüfe deine Zugangsdaten!',
          info: 'Benutzername oder Passwort falsch!',
        },
      },
      classList: {
        import: {
          success: 'Klassenliste erfolgreich importiert',
          error: 'Klassenliste konnte nicht hinzugefügt werden',
          already: 'Zu importierende ID bereits vergeben!',
          unique: 'CSV: IDs müssen einzigartig sein!',
          headerError: 'Spaltenüberschriften sind nicht korrekt!',
          formatError: 'Ihre CSV Datei ist nicht im UTF-8 Format!',
        },
      },
      code: {
        success: 'PDF erfolgreich heruntergeladen',
        error: 'PDF konnte nicht heruntergeladen werden.',
      },
      students: {
        delete: {
          success: 'Schüler*in erfolgreich gelöscht',
          error: 'Schüler*in konnte nicht gelöscht werden!',
        },
        edited: {
          success: 'Schüler*in erfolgreich geändert',
          error: 'Schüler*in konnte nicht geändert werden!',
        },
        created: {
          success: 'Schüler*in erfolgreich hinzugefügt',
          error: 'Schüler*in konnte nicht hinzugefügt werden!',
          max: 'Maximale Schüleranzahl erreicht!',
        },
      },
      patients: {
        delete: {
          success: 'Kind erfolgreich gelöscht',
          error: 'Kind konnte nicht gelöscht werden!',
        },
        edited: {
          success: 'Kind erfolgreich geändert',
          error: 'Kind konnte nicht geändert werden!',
        },
        created: {
          success: 'Kind erfolgreich hinzugefügt',
          error: 'Kind konnte nicht hinzugefügt werden!',
          max: 'Maximale Schüleranzahl erreicht!',
        },
      },
      teachers: {
        delete: {
          success: 'Lehrer*in erfolgreich gelöscht',
          error: 'Lehrer*in konnte nicht gelöscht werden!',
        },
        edited: {
          success: 'Lehrer*in erfolgreich geändert',
          error: 'Lehrer*in konnte nicht geändert werden!',
          errorMail: 'E-Mail-Adresse bereits registriert!',
        },
        created: {
          success: 'Lehrer*in erfolgreich hinzugefügt',
          error: 'Lehrer*in konnte nicht hinzugefügt werden!',
          errorMail: 'E-Mail-Adresse bereits registriert!',
        },
      },
      therapists: {
        delete: {
          success: 'Therapeut*in erfolgreich gelöscht',
          error: 'Therapeut*in konnte nicht gelöscht werden!',
        },
        edited: {
          success: 'Therapeut*in erfolgreich geändert',
          error: 'Therapeut*in konnte nicht geändert werden!',
          errorMail: 'E-Mail-Adresse bereits registriert!',
        },
        created: {
          success: 'Therapeut*in erfolgreich hinzugefügt',
          error: 'Therapeut*in konnte nicht hinzugefügt werden!',
          errorMail: 'E-Mail-Adresse bereits registriert!',
        },
      },
      parents: {
        delete: {
          success: 'Elternteil erfolgreich gelöscht',
          error: 'Elternteil konnte nicht gelöscht werden!',
        },
        edited: {
          success: 'Elternteil erfolgreich geändert',
          error: 'Elternteil konnte nicht geändert werden!',
          errorMail: 'E-Mail-Adresse bereits registriert!',
        },
        created: {
          success: 'Elternteil erfolgreich hinzugefügt',
          error: 'Elternteil konnte nicht hinzugefügt werden!',
          errorMail: 'E-Mail-Adresse bereits registriert!',
        },
      },
      email: {
        success: 'E-Mail erfolgreich verschickt',
        error: 'E-Mail konnte nicht versendet werden',
      },
      pdf: {
        success: 'PDF erfolgreich heruntergeladen',
        error: 'PDF konnte nicht heruntergeladen werden',
        'single-error':
          'PDF-ERROR: Überprüfen Sie, ob eine Schulstufe gesetzt ist.',
      },
      password: {
        success: 'Passwort erfolgreich geändert.',
        invalid: 'Link ist abgelaufen.',
        error: 'Passwort konnte nicht geändert werden.',
      },
      error: {
        passwordLength: 'Passwort muss mind. 8 Zeichen lang sein.',
        password: 'Bitte Password angeben',
        sameAs: 'Passwörter stimmen nicht überein.',
      },
      'test-end': {
        'save-success': 'Speichern erfolgreich!',
        'save-error': 'Fehler beim Speichern. Bitte versuche es nochmal.',
      },
      'delete-students': {
        warning: 'Wollen Sie wirklich alle Schüler löschen?',
        warning2:
          'Beachten Sie bitte, dass auch alle Testergebnisse und Auswertungen der jeweiligen Schüler gelöscht werden. Wenn gewünscht, können Sie sich diese noch vor dem Löschen herunterladen.',
        success: 'Alle Schüler*innen erfolgreich gelöscht',
        error: 'Alle Schüler*innen konnten nicht gelöscht werden',
      },
    },
    tests: {
      'go-start':
        "Jetzt geht's los! <br> Mache es so schnell und so richtig, <br> wie du kannst.",
      'show-you': 'Leo zeigt dir, wie es geht.',
      'start-now': "Jetzt geht's los!",
    },
    welcomeMail: {
      teacher: {
        info: 'Der/die Lehrer*in erhält eine E-Mail und kann ein eigenes Passwort für den Webseiten-Zugang festlegen.',
        resetInfo:
          'Der/die Lehrer*in erhält eine E-Mail und kann ein neues Passwort für den Webseiten-Zugang festlegen.',
      },
      therapist: {
        info: 'Der/die Therapeut*in erhält eine E-Mail und kann ein eigenes Passwort für den Webseiten-Zugang festlegen.',
        resetInfo:
          'Der/die Therapeut*in erhält eine E-Mail und kann ein neues Passwort für den Webseiten-Zugang festlegen.',
      },
    },
    imprint: {
      title: 'Impressum',
      name: 'Dipl. Päd. Claudia Scherling',
      street: 'Birkenstraße 30a',
      city: '5020 Salzburg',
      country: 'Österreich',
      contact: 'Kontakt',
      tel: '+43 (0) 650 8319761',
      telLink: '00436508319761',
      email: 'info@lesikus.com',
      homepage: 'www.lesikus.com',
      url: 'https://www.lesikus.com',
      bank: {
        name: 'Volksbank Gneis Bezeichnung:',
        owner: 'Claudia Scherling',
        iban: 'IBAN: AT05 4501 0000 0510 8923',
        bic: 'BIC: VBOEATWWSAL',
      },
      info: 'Inhalte sowie Illustrationen (auch auszugsweise) dürfen nur mit ausdrücklicher Genehmigung von Alphaport OG kopiert oder anderweitig veröffentlicht werden.',
      freepik: 'Icons made by',
      flatIcon: 'www.flaticon.com',
      flatIconLink: 'https://www.flaticon.com/',
      from: 'from',
      developed: 'Technische Umsetzung:',
      business: {
        name: 'Einzelpersonenunternehmen',
        type: 'Buch,- Kunst- und Musikalienverlag',
        uid: 'UID-Nummer: ATU64875111',
        tax: 'Steuernummer: 91935/3441',
      },
      ris: 'www.ris.bka.gv.at',
      risLink: 'https://www.ris.bka.gv.at',
      alphaport: {
        name: 'Alphaport OG',
        street: 'Bahnhofstraße 19',
        city: '4722 Peuerbach',
        phone: '+43 (0) 720 30 22 22',
        phoneLink: 'tel:+43720302222',
        email: 'office@alphaport.at',
        emailLink: 'mailto:office@alphaport.at',
        homepage: 'www.alphaport.at',
        homepageLink: 'https://www.alphaport.at',
        ceo: 'Geschäftsführer: Daniel Antlinger, MSc.',
        service: 'Unternehmensgegenstand: IT-Dienstleistung',
        uid: 'UID-Nummer: ATU67590412',
        fn: 'Firmenbuchnummer: FN 389304 g',
        gericht: 'Firmenbuchgericht: Landesgericht Wels',
        firmensitz: 'Firmensitz: 4722 Peuerbach',
        kammer:
          'Kammermitgliedschaft: Wirtschaftskammer Oberösterreich und Salzburg',
        informationspflicht: 'wko.at/alphaport-og',
        ris: "Gewerbeordnung: <a class='fontColor' href='https://www.ris.bka.gv.at'>www.ris.bka.gv.at</a>",
      },
      dataLink: 'https://www.leo-lesetest.com/datenschutz',
    },
    'license-expired': 'Lizenz abgelaufen',
    'no-valid-tests': 'Max. Anzahl an Tests erreicht',
    'data-policy': 'Datenschutzerklärung',
    root: {
      title: 'Willkommen!',
      info: 'Bitte wechseln Sie auf die Webseite,',
      info2: 'die für Ihre Schule freigeschalten wurde.',
      link: 'Die genaue Adresse finden Sie',
      link2: 'in Ihrer Willkommens-Email.',
    },
    parent: {
      account: {
        manage: 'Eltern verwalten',
      },
      info: 'Eltern-Information',
      student: 'Name',
      evaluation: 'Auswertung',
      test: {
        1: 'Je nach Kauf steht Test <strong>Form A</strong> oder der Folgetest <strong>Form B</strong> zur Verfügung. LEO startet automatisch mit der von Ihnen gekauften Form.',
        2: 'LEO sollte  ohne Unterbrechung durchgeführt werden. Im Notfall unterbrechen Sie <strong>NACH</strong> einem der Untertests - also  beispielsweise nach der „Silbensuche“. Setzen Sie nach einer Pause wieder fort.',
        3: 'Sie dürfen ihrem Kind auf keinen Fall helfen, denn das würde die Ergebnisse verfälschen. LEO erklärt Ihrem Kind vorab mit 2 Beispielen, was es tun muss. Wenn es etwas nicht kann, dann ist das nicht schlimm.',
        4: 'Verwenden Sie unbedingt Kopfhörer, falls Ihr Kind die LEO Lesediagnose in einer Umgebung mit Hintergrundgeräuschen durchführen sollte.',
        5: 'Wenn Ihr Kind LEO beendet hat, klicken Sie im Namensfeld auf den Button „Auswertung“.',
      },
      'tests-done': 'Keine Tests offen',
    },
    license: {
      warning: 'Ihre Lizenz läuft in {days} Tage(n) ab.',
      extend: 'Verlängern Sie Ihre Lizenz gleich <a href={url}>hier</a>.',
      'test-extend':
        'Erwerben Sie ein neues Testpaket gleich <a href={url}>hier</a>.',
      url: {
        school: 'https://www.leo-lesetest.com/bestellung/schule',
        therapy: 'https://www.leo-lesetest.com/bestellung/therapeuten',
        parent: 'https://www.leo-lesetest.com/bestellung/elternversion',
      },
      expired: 'Ihre Lizenz ist abgelaufen.',
      'no-valid-tests': 'Max. Anzahl an gekauften Tests ist erreicht.',
    },
    formSwitch: {
      a: 'Form A',
      b: 'Form B',
      enableFormA: 'Form A freischalten',
      enableFormB: 'Form B freischalten',
    },
    classLevel: {
      level: 'Schulstufe',
      label: 'Schulstufe auswählen',
      required: 'Bitte wählen Sie eine Schulstufe für Ihre Klasse aus.',
      info: 'Schulstufe für alle Schüler auswählen: ',
      warning:
        'Über die Klassenliste können Sie eine Schulstufe für die gesamte Klasse auswählen. Die ausgewählte Schulstufe wird <strong>beim Speichern für alle Schüler gesetzt</strong>. Bereits zugewiesene Schulstufen für Schüler*innen werden überschrieben!',
      'evaluation-info':
        'Für eine korrekte Auswertung ist es erforderlich, dass jedem/r Schüler*in eine Schulstufe zugewiesen ist. Sie können entweder eine Schulstufe für die gesamte Klasse auswählen oder auch einzelnen Schüler*innen eine eigene Schulstufe zuweisen.',
      evaluation:
        'Für eine korrekte Auswertung ist es erforderlich, dass jedem/r Schüler*in eine Schulstufe zugewiesen ist.',
      'level-all': '1. Schulstufe für alle Schüler setzen',
      'mixed-title': '2. Schulstufe einzeln zuteilen',
      mixed:
        'Für <strong>schulstufengemischte Klassen</strong> können Sie jedem Schüler eine <strong>eigene Schulstufe</strong> zuweisen. Klicken Sie auf Bearbeiten in der Zeile des jeweiligen Schülers und ändern Sie die Schulstufe.',
      button: 'Stufe für alle Schüler setzen',
      success: 'Schulstufe erfolgreich gesetzt',
      error:
        'Schulstufe konnte nicht gesetzt werden. Versuchen Sie es später noch einmal',
      'single-error': 'Schulstufe konnte nicht geändert werden.',
      'many-error': 'SCHULSTUFE FEHLT: Bitte überprüfen Sie alle Schüler*innen',
      save: 'Schulstufe speichern',
      'single-student':
        'Um einzelnen Schülern eine Schulstufe zuzuweisen, klicken Sie auf Bearbeiten.',
    },
    client: {
      management: 'Kundenverwaltung',
      all: 'Alle Kunden',
      create: 'Kunde erstellen',
      admin: 'Administrator',
      docs: 'Dokumente',
      students: 'Schüleranzahl',
      'delete-students': 'Alle Schüler löschen',
      add: 'Kunde hinzufügen',
      'close-create': 'Abbrechen',
      'new-client': 'Neuer Kunde',
      edit: 'Kunde bearbeiten',
      'save-edit': 'Änderungen speichern',
      delete: 'Kunden löschen',
      avv: 'AVV',
      'client-manual-admin': 'Erste Schritte für Admin',
      'client-manual-teacher': 'Erste Schritte: Lehrer*innen',
      'client-manual-therapist': 'Erste Schritte: Therapeut*innen',
      numOfStudents: 'Schüleranzahl',
      form: {
        name: 'Name angeben',
        id: 'ID angeben',
        zero: '0 an erster Stelle nicht erlaubt!',
        number: 'Nur Zahlen erlaubt!',
        street: 'Straße angeben',
        postcode: 'PLZ angeben',
        minValue: 'Mind. 4-stellig',
        city: 'Ort angeben',
        country: 'Land angeben',
        adminFirstname: 'Vorname angeben',
        adminLastname: 'Nachname angeben',
        adminEmail: 'E-Mail angeben',
        invalidEmail: 'Ungültige E-Mail-Adresse',
        licenseUntil: 'Datum (DD.MM.YYYY) angeben!',
        invalidDate: 'gültiges Datum (DD.MM.YYYY)',
        maxLength: 'Max. 10 Zeichen erlaubt',
        classes: 'Klassen angeben',
        validTests: 'Anzahl der gekauften Tests angeben',
        adminPassword: 'Passwort angeben',
        confirmPassword: 'Passwort bestätigen',
        passwordNoMatch: 'Passwörter stimmen nicht überein',
        minLength: 'Min. 8 Zeichen!',
        slug: 'Slug angeben',
      },
      'property-missing': 'Fehlt',
      property: {
        name: 'Name (schoolName)',
        id: 'ID (schoolId)',
        street: 'Straße',
        postcode: 'PLZ ',
        city: 'Ort',
        country: 'Land',
        adminFirstname: 'Vorname',
        adminLastname: 'Nachname',
        adminEmail: 'E-Mail',
        licenseUntil: 'Lizenz bis DD.MM.YYYY',
        classes: 'Klassen',
        validTests: 'Gekaufte Tests (validTests)',
        adminPassword: 'Passwort',
        confirmPassword: 'Passwort bestätigen',
        slug: 'Slug angeben, z.B vs-alphaport',
        pdfPassword: 'Admin-Passwort für PDF',
      },
      snackbar: {
        add: {
          success: 'Kunde erfolgreich hinzugefügt',
          error: 'Kunde konnte nicht hinzugefügt werden',
          conflictError: 'School Id bereits vergeben',
          slugError: 'Slug bereits vergeben',
          adminEmailError: 'Admin Email bereits vergeben',
          adminError: 'Admin konnte nicht hinzugefügt werden',
        },
        edit: {
          success: 'Kunde erfolgreich geändert',
          error: 'Kunde konnte nicht geändert werden',
        },
        pdf: {
          password: 'Bitte Admin-Passwort angeben',
        },
        delete: {
          students: {
            question:
              'Wollen Sie wirklich alle Schüler + Testresultate löschen?',
            success: 'Alle Schüler*innen erfolgreich gelöscht',
            error: 'Alle Schüler*innen konnten nicht gelöscht werden',
          },
          client: {
            question: 'Wollen Sie diesen Kunden wirklich löschen?',
            info: 'Alle Mitglieder und Testresultate werden gelöscht!',
            success: 'Kunde erfolgreich gelöscht',
            error: 'Kunde konnte nicht oder nicht vollständig gelöscht werden',
          },
        },
      },
    },
    'test-end': {
      'save-error': 'Leo konnte deinen Test noch nicht speichern.',
      'save-error2': 'Bitte drücke auf Speichern.',
      'final-success': 'Es geht weiter...',
    },
    mongo: {
      import: {
        button: 'Kunden in MongoDb importieren',
        success: 'Daten erfolgreich importiert',
        error: 'Daten konnten nicht importiert werden',
      },
    },
    manageClass: {
      title: 'Klassen verwalten',
      fetch: {
        error: 'Klassen konnten nicht geladen werden',
      },
      add: {
        title: 'Klasse hinzufügen',
        button: 'Hinzufügen',
        nameError: 'Klassenname angeben',
        success: 'Klasse erfolgreich hinzugefügt',
        error: 'Klasse konnte nicht hinzugefügt werden',
      },
      edit: {
        title: 'Klasse umbenennen',
        button: 'Umbenennen',
        success: 'Klassenname erfolgreich geändert',
        error: 'Klassenname konnte nicht geändert werden',
        conflictError: 'Klassenname bereits vergeben',
        info: 'Bei einer Änderung bleiben Klassenlehrer*innen, Schüler*innen sowie Testresultate erhalten.',
      },
      delete: {
        button: 'Löschen',
        info: `Wollen Sie die Klasse {name} wirklich löschen?`,
        info2: 'Alle Schüler*innen und Testresultate werden auch gelöscht!',
        success: 'Klasse erfolgreich gelöscht',
        error: 'Klasse konnte nicht gelöscht werden',
      },
    },
    manageTests: {
      delete: {
        warning: 'Wollen Sie wirklich die Testergebnisse von {name} löschen?',
        warningAll:
          'Wollen Sie wirklich alle Testergebnisse der Klasse {grade} löschen?',
        button: 'Ergebnisse löschen',
        buttonAll: 'Alle Testergebnisse löschen',
        info: 'Testergebnisse werden gelöscht und Schüler*in kann den Test erneut starten.',
        infoAll:
          'Testergebnisse werden gelöscht und Schüler*innen können den Test erneut starten.',
        success: {
          single: 'Testergebnisse erfolgreich gelöscht',
          all: 'Alle Testergebnisse erfolgreich gelöscht',
        },
        noClass: 'Keine Klasse zugeteilt',
        error: 'Testergebnisse konnten nicht gelöscht werden',
      },
      title: 'Testergebnisse löschen',
      info: 'Sie können die Testergebnisse einzelner Schüler*innen oder der gesamten Klasse löschen. Es werden die Ergebnisse von Form A als auch Form B gelöscht. Die Schüler*innen können den Test dann erneut starten.',
    },
  },
  en: {},
};
